$info: #3373a5;
$default: #ddd;
$warning: #f2711c;
$success: #5ab031;

$font-family-base: 'Open Sans', sans-serif;
$font-size-base: 0.8125rem;

@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800&display=swap');
@import '~bootstrap/scss/bootstrap.scss';

h1, .h1, .h1, h2, .h2, .h2, h3, .h3, .h3, h4, .h4, .h4, h5, .h5, .h5, h6, .h6, .h6 {
  margin-bottom: 0.9375rem;
}

a {
    text-decoration: none;
}

.btn-outline-default {
    border-color: #ddd;
    color: var(--bs-body-color)
}

.btn:focus {
  box-shadow: none;
}

.alert-default {
  color: #313131;
  background-color: #ffffff;
  border-color: #eaeaea;
}

.btn.btn-success {
    color: #ffffff;
    background-color: var(--bs-success);
    border-color: var(--bs-success);
}

.btn.btn-primary-dark {
    color: #fff;
    background-color: #3d4e5d;
    border-color: #3d4e5d;
}

.dropdown-menu-custom {
    padding: 0px;
    transform: translate(0px, 60px) !important;

    .dropdown-item {
        min-width: 220px;
        padding: 0.75rem 1rem;
    }
}

.dropdown-menu-custom.dropdown-menu-dark {
    background-color: #273541;

    .dropdown-item:hover {
        background-color: #23303b !important;
    }

    ::after {
        bottom: 100%;
        left: 27px;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(0,0,0,0);
        border-bottom-color: #33414e;
        border-width: 5px;
        margin-left: -5px;
    }
}
