.turmas {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 1rem;

    .turma {
        background-color: #ffffff;

        img {
            width: 100%;
            border-bottom: solid 2px #e5e5e5;
        }

        > div {
            padding: 1rem;
            color: #000000;
        }
    }
}
