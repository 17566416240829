.footer {
    background: #33414e;
}

.buttonToOpen {
    span {
        background: #33414e;
        display: inline-block;
        padding: 1px 10px;
        margin-left: 70%;
        border-radius: 6px 6px 0 0;
        cursor: pointer;

        img {
            height: 20px;
            -webkit-filter: grayscale(100%) brightness(0%) contrast(100%) invert(100%);
        }
    }
}
